@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Ultralight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-UltralightItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-ThinItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./../fonts/SF-Pro-Display-BlackItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
