body {
  font-size: 16px;
  letter-spacing: -0.025em;
  background-color: #fafafa;
  box-sizing: border-box;
}

.ProjectItem:last-child > .FooterFiller {
  display: block;
}

.line1,
.line2,
.line3 {
  transition: transform 0.2s;
}

.line1:not(.open),
.line2:not(.open),
.line3:not(.open) {
  transition: transform 0.2s 0.2s;
}

.line1 > .inner,
.line2 > .inner,
.line3 > .inner {
  transition: transform 0.2s 0.2s;
}

.line1:not(.open) > .inner,
.line2:not(.open) > .inner,
.line3:not(.open) > .inner {
  transition: transform 0.2s;
}

.line1.open {
  transform: translateY(8.4px);
}
.line3.open {
  transform: translateY(-8.4px);
}

.line1.open > .inner {
  transform: rotate(45deg);
}
.line2.open > .inner {
  transform: rotate(45deg);
}
.line3.open > .inner {
  transform: rotate(-45deg);
}

.pen {
  fill: none;
  stroke: black;
  stroke-width: 8;
  stroke-linecap: round;
  stroke-linejoin: round;

  stroke-dashoffset: 0;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.line-sig-1 {
  clip-path: url(#sig-1);

  stroke-dasharray: 110;
  animation-name: draw-sig-1;
}
@keyframes draw-sig-1 {
  0% {
    stroke-dashoffset: 110;
  }

  10% {
    stroke-dashoffset: 0;
  }
}

.line-sig-2 {
  clip-path: url(#sig-2);

  stroke-dasharray: 68;
  animation-name: draw-sig-2;
}
@keyframes draw-sig-2 {
  0%,
  8% {
    stroke-dashoffset: 68;
  }

  20% {
    stroke-dashoffset: 0;
  }
}

.line-sig-3 {
  clip-path: url(#sig-3);

  stroke-dasharray: 34;
  animation-name: draw-sig-3;
}
@keyframes draw-sig-3 {
  0%,
  16% {
    stroke-dashoffset: 34;
  }

  20% {
    stroke-dashoffset: 0;
  }
}

.line-sig-4 {
  clip-path: url(#sig-4);

  stroke-dasharray: 42;
  animation-name: draw-sig-4;
}
@keyframes draw-sig-4 {
  0%,
  18% {
    stroke-dashoffset: 42;
  }

  24% {
    stroke-dashoffset: 0;
  }
}

.line-sig-5 {
  clip-path: url(#sig-5);

  stroke-dasharray: 43;
  animation-name: draw-sig-5;
}
@keyframes draw-sig-5 {
  0%,
  22% {
    stroke-dashoffset: 43;
  }

  31% {
    stroke-dashoffset: 0;
  }
}

.line-sig-6 {
  clip-path: url(#sig-6);

  stroke-dasharray: 10;
  animation-name: draw-sig-6;
}
@keyframes draw-sig-6 {
  0%,
  28% {
    stroke-dashoffset: 10;
  }

  33% {
    stroke-dashoffset: 0;
  }
}

.line-sig-7 {
  clip-path: url(#sig-7);

  stroke-dasharray: 13;
  animation-name: draw-sig-7;
}
@keyframes draw-sig-7 {
  0%,
  31% {
    stroke-dashoffset: 13;
  }

  36% {
    stroke-dashoffset: 0;
  }
}

.line-sig-8 {
  clip-path: url(#sig-8);

  stroke-dasharray: 8;
  animation-name: draw-sig-8;
}
@keyframes draw-sig-8 {
  0%,
  34% {
    stroke-dashoffset: 8;
  }

  40% {
    stroke-dashoffset: 0;
  }
}

.line-sig-9 {
  clip-path: url(#sig-9);

  stroke-dasharray: 20;
  animation-name: draw-sig-9;
}
@keyframes draw-sig-9 {
  0%,
  38% {
    stroke-dashoffset: 20;
  }

  43% {
    stroke-dashoffset: 0;
  }
}

.line-sig-10 {
  clip-path: url(#sig-10);

  stroke-dasharray: 28;
  animation-name: draw-sig-10;
}
@keyframes draw-sig-10 {
  0%,
  41% {
    stroke-dashoffset: 28;
  }

  49% {
    stroke-dashoffset: 0;
  }
}

.line-sig-11 {
  clip-path: url(#sig-11);

  stroke-dasharray: 13;
  animation-name: draw-sig-11;
}
@keyframes draw-sig-11 {
  0%,
  46% {
    stroke-dashoffset: 13;
  }

  52% {
    stroke-dashoffset: 0;
  }
}

.line-sig-12 {
  clip-path: url(#sig-12);

  stroke-dasharray: 6;
  animation-name: draw-sig-12;
}
@keyframes draw-sig-12 {
  0%,
  49% {
    stroke-dashoffset: 6;
  }

  53% {
    stroke-dashoffset: 0;
  }
}

.line-sig-13 {
  clip-path: url(#sig-13);

  stroke-dasharray: 13;
  animation-name: draw-sig-13;
}
@keyframes draw-sig-13 {
  0%,
  50% {
    stroke-dashoffset: 13;
  }

  55% {
    stroke-dashoffset: 0;
  }
}

.line-sig-14 {
  clip-path: url(#sig-14);

  stroke-dasharray: 7;
  animation-name: draw-sig-14;
}
@keyframes draw-sig-14 {
  0%,
  53% {
    stroke-dashoffset: 7;
  }

  58% {
    stroke-dashoffset: 0;
  }
}

.line-sig-15 {
  clip-path: url(#sig-15);

  stroke-dasharray: 24;
  animation-name: draw-sig-15;
}
@keyframes draw-sig-15 {
  0%,
  56% {
    stroke-dashoffset: 24;
  }

  64% {
    stroke-dashoffset: 0;
  }
}

.line-sig-16 {
  clip-path: url(#sig-16);

  stroke-dasharray: 2;
  animation-name: draw-sig-16;
}
@keyframes draw-sig-16 {
  0%,
  64% {
    stroke-dashoffset: 2;
  }

  74% {
    stroke-dashoffset: 0;
  }
}
