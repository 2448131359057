:root {
  --apple-gray: #a2aaad;
  --almost-black: #363636;
  --background: #fafafa;
  --contrast-gray: #5d6465;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
